document.addEventListener('DOMContentLoaded', ()=> {

    const miniEl = document.querySelector('.c-mini-lottery');
    const bannerEl = document.querySelector('.c-lottery');

    if(!miniEl && !bannerEl)
        return;

    miniEl.addEventListener('click', function() {
        document.body.classList.add('is-lottery-banner');
    });

    bannerEl.querySelector('.js-closePopup').addEventListener('click', function() {
        document.body.classList.remove('is-lottery-banner');
        localStorage.setItem('lottery', 1);
    });

    if(localStorage.getItem('lottery') === null) {
        document.body.classList.add('is-lottery-banner');
    }

    document.body.classList.add('is-lottery');
});
